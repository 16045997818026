.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #111111;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.home__avatar {
  width: 180px;
  height: 180px;
  overflow: hidden;
  border-radius: 40px;
  box-shadow: 0px 0px 0px 2px #cbcaca;
}

.home__avatar img {
  width: 100%;
}

.home__details {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.home__details__intro {
  font-size: 20px;
  text-align: center;
  margin: 24px;
  padding: 25px;
  line-height: 45px;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.home__details__social {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.home__details__social__name {
  border-radius: 25px;
  margin: 5px;
  font-size: 16px;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
  background: bisque;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px;
  border-bottom: 0;
}

.home__details__social__icon {
  margin-right: 5px
}
 
.home__details__social__icon:focus,
.home__details__social__icon:visited {
  color: inherit;
  text-decoration: none;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #ffa952;
  font-size: 25px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-star:before {
  content: "\e9d9";
}
.icon-fork:before {
  content: "\e903";
}
.icon-instagram:before {
  content: "\ea92";
}

.icon-x:before {
  content: "\e900";
}

.icon-linkedin:before {
  content: "\eac9";
}
.icon-chevron-up:before {
  content: "\e902";
}
.icon-chevron-down:before {
  content: "\e901";
}
.icon-medium:before {
  content: "\e904";
}
.icon-email:before {
  content: "\ea83";
}
.icon-github:before {
  content: "\eab0";
}

/* Writing screen */
.writing {
  display: flex;
  flex-direction: column;
  align-items: start;
  color: white;
  background-color: #111111;
  width: 100%;
  min-height: 100vh;
  padding: 0 10%;
  position: relative;
}

/* List */
.list {
  list-style: none;
  counter-reset: section;
  padding-left: 0;
}

.list__item {
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
  position: relative;
  padding-left: 44px;
  font-size: 22px;
  line-height: 40px;
  text-align: left;
}

.list__item:before {
  counter-increment: section;
  content: "" counter(section) ". ";
  position: absolute;
  left: 0;
  top: 0px;
}

.list__item__heading {
  margin-bottom: 0;
}

.list__item__body {
  margin-top: 0px;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
}

a:link {
  /* color: #60b4fd;  00d921 f69425*/
  color: #ffa952;
  background-color: transparent;
  text-decoration: none;
}

a:visited {
  color: #ffa952;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #ffa952;
  background-color: transparent;
  text-decoration: underline;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?29rezi');
  src:  url('fonts/icomoon.eot?29rezi#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?29rezi') format('truetype'),
    url('fonts/icomoon.woff?29rezi') format('woff'),
    url('fonts/icomoon.svg?29rezi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
