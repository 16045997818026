:root {
  --header-opacity: 1;
}

.no-scroll {
  overflow: hidden;
}

body,
html {
  font-family: 'Space Grotesk';

  /* 👇️ or use Operating system default fonts 👇️ */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

.header {
  /* background-color: #1e1e1e;
    background-color: rgba(0, 0, 0, 0.7); */
  background-color: rgba(0, 0, 0, var(--header-opacity));
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  backdrop-filter: blur(5px);
}

.header.transparent {
  background-color: transparent;
  backdrop-filter: none;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-left {
  flex: 1;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
  font-size: 35px;
  margin-left: 16px;
  color: #fff;
  font-weight: 500;
  width: fit-content;
}

.header-empty {
  flex: 50%;
}

.header-button {
  border: 1px solid transparent;
  /* background-color: #877ffd; */
  background-color: #6a60ff;
  border-radius: 7px;
  color: #fff;
  padding: 10px 32px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'Space Grotesk';
  font-weight: 600;
  margin-right: 16px;
}

.header-button:hover {
  background-color: #877ffd;
}

.background-image {
  /* Replace 'your-image-url' with the URL of your background image */
  background-image: url('../assets/website_bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  /* Keep the image fixed even when scrolling */
  min-height: 100vh;
  /* Set the minimum height to ensure the image covers the entire viewport */
  position: relative;
}

.background-image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* 50% transparency */
  z-index: 1;

}

.container {
  display: flex;
  height: calc(100vh - 55px);
}

.left-div {
  flex: 25%;
  /*background-color: #2f2f2f; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-div {
  flex: 75%;
  margin-top: 0px;
  flex-direction: column;
  /*background-color: #2f2f2f;*/
}

.separator {
  margin: 0px 0;
  height: 0.2px;
  padding: 0.5px;
  background-color: #2f2f2f;
  background-image: linear-gradient(to right, #4c4c4c, #bbb, #4c4c4c);
  /*background-image: linear-gradient(to right, #655bff, #d2cfff, #655bff);*/
  border: none;
}

.second-fold {
  height: auto;
  display: flex;
  justify-content: start;
  /*background-color: #2f2f2f;*/
  width: 100vw;
}

.company-cards {
  gap: 20px;
}

.scrollable-content {
  max-height: calc(100vh - 90px);
  /* Set the maximum height to fill the remaining viewport */
  overflow-y: auto;
  /* Enable vertical scrolling when content exceeds the height */
}

.experience_p {
  font-size: 30px;
  color: white;
  margin-left: 25px;
  margin-top: 90px;
  margin-bottom: 10px;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.company-cards-container {
  flex: 1;
  /* Allow the container to occupy the available vertical space */
  max-height: calc(100vh - 240px);
  /* Adjust the height based on your specific layout */
  overflow-y: auto;
  /* Enable vertical scrolling when content exceeds the height */
  /* Add some padding for better spacing */
  padding: 0px;
}

.company-cards-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Two cards in each row */
  gap: 20px;
  /* Adjust the gap between cards */
  justify-content: center;
  /* Center the cards horizontally */
  margin-top: 20px;
  /* Add some spacing at the top */
}

.company-card {
  flex: 1;
  color: #fff;
  background-color: #000000;
  /* background-color:#1e1e1e; */
  padding: 20px;
  margin: 25px 35px 25px 25px;
  border-radius: 5px;
  border: 0.1px solid #6a60ff66;
  transition: transform 0.3s, z-index 0.3s;
  transform-origin: center;
  /*border: 0.1px solid #04abff5e;
    /*border: 1px solid #58585883;*/
  /*box-shadow: 0 1.5px 1px rgba(255, 255, 255, 0.03); */
}

.company-card:hover {
  border-color: #877ffaad;
  cursor: pointer;
  z-index: 1;
}

.company-card-position {
  font-size: 18px;
}

.company-card-description {
  font-size: 16px;
  color: #aeaeae;
}

.company-card-position-name {
  font-size: 18px;
}

.company-card-position-period {
  font-size: 14px;
  color: #939393;
}

.company-card-header {
  display: flex;
  justify-content: space-between;
}

.company-logo {
  width: auto;
  /* Set the width and height of the logo */
  height: 30px;
  margin-bottom: 10px;
  /* Add spacing between the logo and other details */
}

.bottom-panel-open-logo {
  width: 24px;
  height: 24px;
  visibility: hidden;
}

.medium-blogs {
  overflow-x: auto;
  margin-top: 10px;
  margin-bottom: 40px;
  /* Add some spacing between the heading and the blogs */
  /*margin-left: 35px;*/
  flex: 1;
  /* Allow the container to occupy available vertical space */
  display: flex;
  flex-direction: column;
}

.medium-blogs h2 {
  color: #fff;
  font-size: 28px;
  margin-left: 35px;
  text-align: left;
  /* Center the heading */
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.blogs-wrapper {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  padding: 20px 35px;
  /* Add some spacing for the container */
  /* Add scrollbar styles */
  scrollbar-width: thin;
  white-space: nowrap;
  margin-left: 0px;
  /* Move the margin-left to the new wrapper div */
}

.blogs-container {
  /* overflow-x: auto;  Enable horizontal scrolling */
  /* padding: 20px 0; /* Add some spacing for the container */
  /* Add scrollbar styles */
  /*scrollbar-width: thin;
    white-space: nowrap;*/
  display: inline-block;
}

.blogs-container::-webkit-scrollbar {
  width: 15px;
}

.blogs-container::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.blog-item {
  margin-right: 50px;
  /* Add some spacing between blog items */
  width: 420px;
  /* Set a fixed width for the card */
  overflow: hidden;
  /* Hide any content that overflows */
  border-radius: 10px;
  display: inline-block;
  border: 0.1px solid #6a60ff66;
  position: relative;
  /* Set position to relative for positioning the .blog-item-details */
  transition: transform 0.3s, z-index 0.3s;
  /* Add transition for the transform property */
  transform-origin: center;
  /* Set the transform origin to the left side */
}

.blog-item:hover {
  border-color: #877ffaad;
  transform: scale(1.1);
  /* Scale up the card on hover */
  z-index: 1;
  /* Bring the hovered card to the top of other cards */
}

.blog-item .blog-item-details {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  /* Semi-transparent background for the details */
  color: #fff;
  padding: 20px;
  opacity: 0;
  /* Initially set the details to be invisible */
  transition: opacity 0.3s;
  /* Add transition for opacity property */
}

.blog-item:hover .blog-item-details {
  opacity: 1;
  /* Make the details visible on hover */
}

/* Add styles for the details content (h3, p, etc.) */
.blog-item .blog-item-details h3 {
  margin: 0;
  font-size: 18px;
}

.blog-item .blog-item-details p {
  margin: 5px 0 0;
  font-size: 14px;
}

.blog-image-container {
  position: relative;
}

.blog-image {
  width: 100%;
  height: 300px;
  display: grid;
  border-radius: 10px 10px 0 0;
  /* Rounded corners for the top */
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
  padding: 10px;
  border-radius: 0 0 10px 10px;
  word-wrap: break-word;
  white-space: normal;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.overlay p {
  margin: 5px 0 0;
  font-size: 13px;
  color: #c3c3c3;
}

.overlay b {
  font-size: 15px;
  word-wrap: break-word;
}

.footer {
  /*background-color: #2f2f2f;*/
  padding: 20px;
  text-align: center;
  color: #ffffff;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

/* Styles for the CompanySheet */
.company-sheet-container {
  position: fixed;
  top: 0;
  right: 0;
  /* Align the sheet to the right side of the screen */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  /* Move the sheet to the right */
  align-items: center;
  background-color: rgba(135, 127, 250, 0.1);
  backdrop-filter: blur(5px);
  z-index: 999;
  /* Ensure the sheet is above all other components */
  overflow-y: hidden;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.company-sheet-header {
  width: 100%;
  height: 30px;
  background-color: #000000;
  padding: 10px;
  display: flex;
  align-items: center;
}

.company-sheet {
  position: relative;
  width: 45%;
  max-width: 90%;
  height: 100vh;
  /* Make the sheet take the available window space */
  background-color: #121212;
  border-radius: 0px;
  padding: 20px 0px 20px 0px;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  /* Align items one below the other */
  align-items: flex-start;
  /* Align items to the left side */
}

.opening {
  animation: slideIn 500ms forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0%);
  }
}

.closing {
  animation: slideOut 500ms forwards;
}

@keyframes slideOut {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

.close-button {
  position: relative;
  right: 10px;
  left: 15px;
  width: 25px;
  height: 25px;
  background: none;
  border: none;
  cursor: pointer;
  align-items: start;
  z-index: 2;
}

.company-logo-in-company-sheet {
  margin: 0px 0px 0px 35px;
}

.company-details {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 0px;
  height: fit-content;
  padding: 0px 20px 10px 30px;
  overflow: auto;
  background-color: #121212;
}

/*.company-details p {
    margin-bottom: 10px;
    color: #fff;
  } */

.company-details a {
  color: #007bff;
  text-decoration: none;
}

.company-details img {
  margin-top: 20px;
}


.company-info {
  display: flex;
  align-items: baseline;
}

.company-name {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #fff;
  margin-top: 10px;
}

.company-position {
  font-size: 22px;
  color: #ffffff;
}

.company-website {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #fff;
  margin-top: 30px;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.company-website p {
  font-size: 15px;
  font-weight: 300;
}

.company-website a {
  color: #04abff;
}

.company-description-timeperiod {
  color: #939393;
  font-size: 14px;
  margin-left: 5px;
}

.tech-stack {
  margin-top: 0px;
  margin-bottom: 20px;
}

.tech-stack p {
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.tech-items {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 14px;
}

.tech-item {
  background-color: #555555;
  border: 1px solid #999999;
  border-radius: 2px;
  padding: 6px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

/* .company-details {
    display: flex;
  }
  
  .company-logo {
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
  
  .company-logo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .company-info {
    flex: 1;
  }
  */



@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?29rezi');
  src: url('../fonts/icomoon.eot?29rezi#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?29rezi') format('truetype'),
    url('../fonts/icomoon.woff?29rezi') format('woff'),
    url('../fonts/icomoon.svg?29rezi#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@media screen and (max-width: 767px) {
  .header {
    height: 80px;
    justify-content: center;
  }

  .header h1 {
    font-size: 20px;
  }

  .header-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .header-left {
    margin-left: 0;
    font-size: 22px;
  }

  .header-empty {
    flex: 1;
    display: none;
  }

  .header-button {
    margin-right: 0;
    padding: 10px 15px 10px 15px;
    font-size: 14px;
    font-weight: bold;
  }

  .container {
    flex-direction: column;
    /* Stack left and right divs below each other */
    height: auto;
  }

  .left-div,
  .right-div {
    flex: 100%;
    /* Set full width for both divs on smaller screens */
    margin-bottom: 30px;
  }

  .left-div {
    margin-top: 70px;
    margin-bottom: 30px;
  }

  .scrollable-content {
    max-height: fit-content;
  }

  .second-fold {
    margin-bottom: 0px;
  }

  .experience_p {
    margin-left: 25px;
    font-size: 25px;
    /* Reduce font size for smaller screens */
    margin-top: 35px;
    /* Adjust margin for experience paragraph */
  }

  .company-cards-container {
    max-height: fit-content;
  }

  .company-card {
    margin: 15px 25px;
    /* Adjust margin for company cards */
  }

  .medium-blogs {
    margin-left: 25px;
    margin-bottom: 0px;
  }

  .medium-blogs h2 {
    font-size: 25px;
    /* Reduce font size for smaller screens */
    margin-left: 0px;
  }

  .blogs-container {
    padding: 10px 0;
    /* Adjust spacing for blog container */
    margin-bottom: 0px;
  }


  .blogs-wrapper {
    overflow-x: auto;
    /* Enable horizontal scrolling */
    padding: 0px 0px;
    /* Add some spacing for the container */
    /* Add scrollbar styles */
    scrollbar-width: thin;
    white-space: nowrap;
    margin-left: 0px;
    /* Move the margin-left to the new wrapper div */
  }

  .blog-item {
    width: 90%;
    /* Adjust blog item width for smaller screens */
    margin: 0px 25px 10px 0px;
    /* Center blog items and add spacing */
    margin-bottom: 20px;
    height: auto;
  }

  .blog-item:hover {
    transform: none;
    z-index: 0;
  }

  .blog-image {
    width: 100%;
    height: 270px;
    display: grid;
    border-radius: 10px 10px 0 0;
    /* Rounded corners for the top */
  }

  .overlay {
    height: auto;
  }

  .overlay b {
    font-size: 15px;
    height: 40px;
  }

  .company-sheet-container {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: fixed;
    width: 100%;
    height: 100%;
    justify-content: flex-end;
    /* Move the sheet to the right */
    background-color: rgba(135, 127, 250, 0.1);
    backdrop-filter: blur(5px);
    z-index: 999;
    /* Ensure the sheet is above all other components */
    overflow-y: auto;
    /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
  }

  .company-sheet {
    width: 100%;
    max-height: 80%;
    max-width: fit-content;
    align-items: flex-end;
    padding: 0px 0px 20px 0px;
  }

  @keyframes slideIn {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0%);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateY(0%);
    }

    to {
      transform: translateY(100%);
    }
  }

  .close-button {
    position: absolute;
    right: 20px;
    left: initial;
    cursor: pointer;
    transform: rotate(90deg);
  }

  .company-sheet-header {
    width: 100%;
    height: 30px;
    background-color: #09090942;
  }

  .company-details {
    padding: 0px 20px 20px 25px;
    margin-left: 0px;
  }

  .company-details img {
    margin-top: 0px;
  }

  .company-info {
    display: flex;
    align-items: baseline;
    flex-direction: column;
  }

  .company-description-timeperiod {
    margin-top: 0px;
    margin-left: 0px;
  }

  .tech-items {
    margin-top: 14px;
  }

  .company-position {
    margin-bottom: 6px;
  }

  .bottom-panel-open-logo {
    visibility: visible;
  }

  .company-card-position {
    font-size: 16px;
  }

  .company-card-position-name {
    font-size: 16px;
  }

  .company-card-position-period {
    font-size: 13px;
  }

  .company-card-description {
    font-size: 15px;
    /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
  }
}
