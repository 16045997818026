.markdown-content {
  color: #ffffff;
  font-size: 16px;
  line-height: 2;
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

/* Style for paragraph elements */
.markdown-content p {
  /* Your styles for paragraphs */
  /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}
