.profile-details {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-image-container {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.center-image {
    width: auto;
    height: 245px;
    overflow: hidden;
    border-radius: 150px;
    margin-top: 95px;
    /*border: 1px solid #aca6fc6f;*/
    border: 1px solid #ffa95252;
    max-width: 100%;
    max-height: 100%;
}

.center-image:hover {
    border-color: #ffa95281;
}

.location {
    display: flex;
    flex-direction: row;
    color: #d3d3d3;
    font-size: 15px;
    font-weight: bold;
    /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.location img {
    padding: 0px 10px 0px 0px;
    width: 14px;
    height: auto;
}

.bio {
    font-size: 16px;
    font-weight: 500;
    color: #e6e5e5;
    margin-top: 10px;
    margin-left: 20px;
    padding: 0px 15px;
    line-height: 1.5;
    /*font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;*/
}

.social-details {
    display: flex;
    justify-content: left;
    margin-top: 20px;
    margin-left: 15px;
}

.clickable-image {
    width: 25px;
    height: 25px;
    margin: 0 20px;
    /* Adjust the spacing between images */
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.2s;
    display: inline-block;
    position: relative;
}

.clickable-image::after {
    content: '';
    /* Empty content to create the pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    /* Transparent background, change alpha value for opacity */
    transition: 0.2s;
    /* Add transition for the background-color property */
}

.clickable-image:hover {
    transform: scale(1.4);
    /* Add a small scale effect on hover */
}

@media screen and (max-width: 767px) {
    .profile-details {
        display: flex;
        flex-direction: column;
    }

    .profile-image-container {
        margin-top: 20px;
        /* Adjust image container margin on smaller screens */
    }

    .location {
        margin-top: 5px;
        font-weight: 600;
    }

    .center-image {
        margin-top: 0px;
    }

    .bio {
        font-size: 15px;
        text-align: start;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 0px 15px;
        line-height: 1.5;
    }

    .social-details {
        flex-wrap: wrap;
        /* Allow images to wrap on smaller screens */
        justify-content: center;
        /* Center images on smaller screens */
    }

    .clickable-image {
        width: 30px;
        /* Adjust clickable image size for smaller screens */
        height: 30px;
        margin: 20px;
        /* Adjust spacing between images */
    }

    .social-details {
        margin-left: 0px;
    }

}
